<template>
  <v-card>
    <v-card-title>
      <span class="headline font-weight-light" v-if="plan.id">
        {{ $t('gemsPlans.editPlan') }}
      </span>
      <span class="headline font-weight-light" v-else>
        {{ $t('gemsPlans.createPlan') }}
      </span>
      <v-spacer></v-spacer>
      <v-btn text icon @click="$emit('close')" class="mr-0">
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-card-title>
    <v-card-text class="mt-5">
      <v-form ref="managePlanForm">
        <v-row>
          <v-col md="12" class="py-0">
            <h3>{{ $t('common.gems') }}</h3>
          </v-col>
        </v-row>
        <v-row>
          <v-col md="12">
            <v-text-field
              solo
              class="pa-0 my-0"
              v-model="plan.gems"
              :rules="[rules.required]"
              :label="$t('common.gems')"
              type="number"
            ></v-text-field>
          </v-col>
        </v-row>

        <v-row>
          <v-col md="12" class="py-0">
            <h3>{{ $t('common.price') }}</h3>
          </v-col>
        </v-row>
        <v-row>
          <v-col md="12">
            <amount-input
              v-model="plan.price"
              :label="$t('common.price')"
              :rules="[rules.required]"
            ></amount-input>
          </v-col>
        </v-row>

        <v-row>
          <v-col md="12" class="py-0">
            <h3>{{ $t('common.promoPrice') }}</h3>
          </v-col>
        </v-row>
        <v-row>
          <v-col md="12">
            <amount-input
              v-model="plan.promoPrice"
              :label="$t('common.promoPrice')"
            ></amount-input>
          </v-col>
        </v-row>
      </v-form>
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn color="primary" text @click="save()" :loading="loading">
        {{ $t('common.save') }}
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import AmountInput from '../../shared/components/AmountInputComponent';
import validators from '../../../core/validators';
import httpService from '../services/gems.plans.http.service';
import mapper from '../mappers/gems.plans.mapper';

export default {
  name: 'manage-gems-plan-modal',
  components: {
    AmountInput
  },
  props: {
    selectedPlan: Object
  },
  data() {
    return {
      loading: false,
      plan: {
        gems: '',
        price: '',
        promoPrice: ''
      },
      rules: {
        required: validators.required(this.$i18n.t('common.requiredField'))
      }
    };
  },
  created() {
    if (this.selectedPlan) {
      this.plan = this.selectedPlan;
    }
  },
  methods: {
    async create() {
      await httpService.create(this.plan);

      this.$notify({
        type: 'success',
        text: this.$i18n.t('gemsPlans.createSuccess')
      });

      this.$emit('close');
    },
    async update() {
      await httpService.update(
        this.plan.id,
        mapper.transformToGemsPlanUpdateModel(this.plan)
      );

      this.$notify({
        type: 'success',
        text: this.$i18n.t('gemsPlans.updateSuccess')
      });

      this.$emit('close', this.plan);
    },
    async save() {
      if (!this.$refs.managePlanForm.validate()) {
        return;
      }

      this.loading = true;

      if (this.plan.id) {
        this.update();
      } else {
        this.create();
      }
    }
  }
};
</script>
