<template>
  <fragment>
    <v-data-table
      :headers="headers"
      :items="items"
      :options.sync="options"
      :server-items-length="totalItems"
      :footer-props="footerProps"
      :loading="loading"
      :loading-text="$t('common.loadingData')"
      :no-data-text="$t('common.noData')"
      class="elevation-1"
    >
      <template v-slot:top>
        <v-toolbar flat color="white" class="mb-4">
          <v-toolbar-title>
            {{ $t('gemsPlans.gemsPlans') }}
          </v-toolbar-title>
          <div class="flex-grow-1"></div>
          <v-btn small fab depressed color="primary" @click="createPlan()">
            <v-icon>mdi-plus</v-icon>
          </v-btn>
        </v-toolbar>
      </template>
      <template v-slot:item.price="{ item }">
        <span>
          {{ item.price | amount }}
        </span>
      </template>
      <template v-slot:item.promoPrice="{ item }">
        <span>
          {{ item.promoPrice | amount }}
        </span>
      </template>
      <template v-slot:item.createdBy="{ item }">
        <span>
          {{ item.createdBy | truncate(20) }}
        </span>
      </template>
      <template v-slot:item.action="{ item }">
        <v-btn
          x-small
          fab
          depressed
          color="primary"
          class="mr-1"
          @click="updatePlan(item)"
        >
          <v-icon>mdi-pencil</v-icon>
        </v-btn>
        <v-btn
          x-small
          fab
          depressed
          color="error"
          class="mr-1"
          @click="deletePlan(item.id)"
        >
          <v-icon>mdi-delete</v-icon>
        </v-btn>
      </template>
    </v-data-table>
  </fragment>
</template>

<script>
import { mapGetters } from 'vuex';
import ManageGemsPlanModal from './ManageGemsPlanModal';
import ConfirmModal from '../../shared/components/ConfirmModal';
import httpService from '../services/gems.plans.http.service';
import approvalStatusesMixin from '../../shared/mixins/approval.statuses.mixin';
import listItemsMixin from '../../shared/mixins/list.items.mixin';

export default {
  name: 'avatar-gems-plans-list',
  computed: {
    ...mapGetters(['currentUser'])
  },
  mixins: [listItemsMixin, approvalStatusesMixin],
  data() {
    return {
      loading: true,
      items: [],
      totalItems: 0,
      headers: [
        {
          text: this.$i18n.t('common.gems'),
          value: 'gems',
          sortable: false
        },
        {
          text: this.$i18n.t('common.price'),
          value: 'price',
          sortable: false
        },
        {
          text: this.$i18n.t('common.promoPrice'),
          value: 'promoPrice',
          sortable: false
        },
        {
          text: this.$i18n.t('common.createdBy'),
          value: 'createdBy',
          sortable: false
        },
        {
          text: this.$i18n.t('common.actions'),
          value: 'action',
          sortable: false
        }
      ]
    };
  },
  methods: {
    async getItems(params) {
      this.loading = true;

      const { result } = await httpService.index(params);

      this.items = result.items;
      this.totalItems = result.totalItems;

      this.loading = false;
    },
    async createPlan() {
      await this.$root.$modal.show(ManageGemsPlanModal);
      this.resetList();
    },
    async updatePlan(plan) {
      const payload = await this.$root.$modal.show(ManageGemsPlanModal, {
        selectedPlan: { ...plan }
      });

      const index = this.items.findIndex(c => c.id === plan.id);

      if (index !== -1) {
        this.items.splice(index, 1, {
          ...this.items[index],
          ...payload
        });
      }
    },
    async deletePlan(id) {
      const confirmed = await this.$root.$modal.show(ConfirmModal, {
        text: this.$i18n.t('gemsPlans.deleteConfirmText')
      });

      if (!confirmed) return;

      await httpService.del(id);
      this.resetList(false);

      this.$notify({
        type: 'success',
        text: this.$i18n.t('gemsPlans.deleteSuccess')
      });
    }
  }
};
</script>
