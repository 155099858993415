<template>
  <v-text-field
    class="mt-2"
    solo
    v-model="displayValue"
    type="text"
    :label="label"
    :rules="rules"
    @blur="onBlur"
    @focus="isInputActive = true"
  ></v-text-field>
</template>

<script>
import formatterService from '../services/amount.formatter.service';

export default {
  name: 'amount-input',
  props: ['value', 'label', 'rules'],
  data() {
    return {
      displayValue: ''
    };
  },
  created() {
    if (this.value) {
      this.displayValue = formatterService.formatDisplayValue(this.value);
    }
  },
  methods: {
    onBlur(ev) {
      const inputValue = ev.target.value;
      const amount = formatterService.formatAmountValue(inputValue);

      this.$emit('input', amount);

      this.displayValue = formatterService.formatDisplayValue(amount);
    }
  }
};
</script>
