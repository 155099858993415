<template>
  <v-container fluid>
    <v-card class="border-top-blue">
      <v-card-text>
        <gems-plans-list></gems-plans-list>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import GemsPlansList from '../components/GemsPlansList';

export default {
  name: 'gems-plans-page',
  components: {
    GemsPlansList
  }
};
</script>
