var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('fragment',[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.items,"options":_vm.options,"server-items-length":_vm.totalItems,"footer-props":_vm.footerProps,"loading":_vm.loading,"loading-text":_vm.$t('common.loadingData'),"no-data-text":_vm.$t('common.noData')},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{staticClass:"mb-4",attrs:{"flat":"","color":"white"}},[_c('v-toolbar-title',[_vm._v(" "+_vm._s(_vm.$t('gemsPlans.gemsPlans'))+" ")]),_c('div',{staticClass:"flex-grow-1"}),_c('v-btn',{attrs:{"small":"","fab":"","depressed":"","color":"primary"},on:{"click":function($event){return _vm.createPlan()}}},[_c('v-icon',[_vm._v("mdi-plus")])],1)],1)]},proxy:true},{key:"item.price",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(" "+_vm._s(_vm._f("amount")(item.price))+" ")])]}},{key:"item.promoPrice",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(" "+_vm._s(_vm._f("amount")(item.promoPrice))+" ")])]}},{key:"item.createdBy",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(" "+_vm._s(_vm._f("truncate")(item.createdBy,20))+" ")])]}},{key:"item.action",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{staticClass:"mr-1",attrs:{"x-small":"","fab":"","depressed":"","color":"primary"},on:{"click":function($event){return _vm.updatePlan(item)}}},[_c('v-icon',[_vm._v("mdi-pencil")])],1),_c('v-btn',{staticClass:"mr-1",attrs:{"x-small":"","fab":"","depressed":"","color":"error"},on:{"click":function($event){return _vm.deletePlan(item.id)}}},[_c('v-icon',[_vm._v("mdi-delete")])],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }