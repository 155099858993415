function transformToGemsPlanUpdateModel(plan) {
  return {
    gems: plan.gems,
    price: plan.price,
    promoPrice: plan.promoPrice
  };
}

export default {
  transformToGemsPlanUpdateModel
};
